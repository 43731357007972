<template>
  <div class="frwmeDiagnosis">
    <ul class="left">
      <li class="bt"><p></p><span>智能处方</span><p></p></li>
      <li class="el-icon-caret-right" :class="idx==1?'xz':''" @click="teb(1)">视光诊断</li>
    </ul>
    <div class="rightp">
      <ViewDiagnosis></ViewDiagnosis>
    </div>
    <el-button class="bc" type="warning" @click="saveData">保存</el-button>
  </div>
</template>

<script>
export default {
  name: "frwmeDiagnosis",
  data() {
    return {
      idx: 1
    }
  },
  methods: {
    teb(idx) {
      this.idx = idx
    },
    saveData() {
      this.saveViewZd()
    },
    saveViewZd() {
      let arr = []
      arr[0] = {
        key: 'Refractive_Diagnosis',
        value: this.$store.state.expertClinic.expertClinicData.Refractive_Diagnosis
      }
      arr[1] = {
        key: 'Refractive_Solution',
        value: this.$store.state.expertClinic.expertClinicData.Refractive_Solution
      }
      arr[2] = {
        key: 'Refractive_Comment',
        value: this.$store.state.expertClinic.expertClinicData.Refractive_Comment
      }
      arr[3] = {
        key: 'StringRefractive_Suggestion',
        value: this.$store.state.expertClinic.expertClinicData.StringRefractive_Suggestion
      }
      arr[4] = {
        key: 'CheckResult_DealSuggestion_Des',
        value: this.$store.state.expertClinic.expertClinicData.CheckResult_DealSuggestion_Des
      }
      arr[5] = {
        key: 'ICD_Diagnosis',
        value: this.$store.state.expertClinic.expertClinicData.ICD_Diagnosis
      }
      this._api.expertClinic.saveExpertClinic(arr, '视光诊断')
    }
  },
  components: {
    ViewDiagnosis: () => import('@/views/expertClinic/viewDiagnosis'),
  }
}
</script>

<style scoped lang="scss">
  .frwmeDiagnosis {
    width: 100vw;
    height: 87vh;
    overflow-y: auto;
    display: flex;
    justify-content: start;
  }

  .left {
    width: 12vw;
    font-size: 14px;
    text-align: left;
    border-right: 1px solid #bcbaba;
    overflow: hidden;
    overflow-y: auto;
    li {
      padding: 0.2rem 0.2rem;
      display: block;
      cursor: pointer;
      &:hover{
        background: #e7e7e7;
      }
    }
    .xz {background: #e7e7e7}
    .bt {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 20%;
        height: 1px;
        background: #bcbaba;
      }
      span {padding: 0 0.1rem}
    }
  }
  .left::-webkit-scrollbar { width: 0 !important }
  .rightp::-webkit-scrollbar { width: 0 !important }

  .rightp {
    width: 87vw;
    height: 87vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .bc {
    position: fixed;
    right: 0;
    bottom: 20vh;
  }
</style>